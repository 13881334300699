import { FormControl, MenuItem, Select, Box, Typography } from "@mui/material";
import { useState } from "react";
import i18n from "../../locales/i18n";
import { useAppSelector } from "../../hooks/use-redux";

const LangChange = () => {
    const [lang, setLang] = useState(i18n.language);
    const changeLang = (language: string) => {
        setLang(language);
        i18n.changeLanguage(language);
    };
    const { isLoading: pinLoading } = useAppSelector(state => state.getByPinReducer);
    const { isLoading: plateLoading } = useAppSelector(state => state.getByPlateReducer);

    return (
        <Box sx={{ width: "60px" }}>
            <FormControl fullWidth>
                <Select
                    defaultValue={lang}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="standard"
                    disableUnderline
                    sx={{ color: "#101828" }}
                    disabled={pinLoading || plateLoading}
                >
                    <MenuItem value={"RU"} onClick={() => changeLang("RU")}>
                        <Box>
                            <Typography sx={textAdapting}>Рус</Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem value={"KG"} onClick={() => changeLang("KG")}>
                        <Box>
                            <Typography sx={textAdapting}>Кыр</Typography>
                        </Box>
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default LangChange;

const textAdapting = () => ({
    fontSize: "16px",
});
